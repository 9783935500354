import './App.css';

function App() {
  return (
    <div className="App">
      <div className="hold_img" id="hold_img"><div className="hold_img_inner"></div></div>
      <div className="hold_button"></div>
    </div>
  );
}

export default App;
